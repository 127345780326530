@import "../sass/colors.scss", "../sass/mixin.scss";

#experience {
  @include page();
  main {
    h1 {
      @include titleFlip;
    }

    section {
      article {
        margin: 16px 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        div {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
        }

        p {
          color: $secondary;
        }
        .border- {
          border-bottom: 1px solid $secondary;
        }
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          padding-bottom: 10px;

          .content {
            h2 {
              color: $primary-emphasis;
            }

            h3 {
              color: $primary;
            }
          }

          .more {
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 50px;
            i {
              color: $primary-emphasis;
              transition: color 0.1s ease, transform 0.1s ease;
              cursor: pointer;
              transform: rotate(-90deg) scale(1.5);

              &:hover {
                color: $primary;
              }
            }

            .arrow-hide {
              transform: rotate(90deg) scale(1.5);
            }
          }
        }

        .description {
          margin-top: 10px;
          margin-bottom: 20px;
        }

        .hide {
          display: none;
        }
      }

      p {
        color: $primary;
      }
    }
  }
}

@media screen and (max-width: 1155px) {
  #experience {
    main {
      h1 {
        font-size: 4em;
        margin-bottom: 12px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #experience {
    padding: 3% 10%;
  }
}

@media screen and (max-width: 420px) {
  #experience {
    margin: 32px 0;
    main {
      h1 {
        font-size: 3em;
        margin-bottom: 12px;
      }
    }
  }
}
