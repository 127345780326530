@import "../sass/colors.scss", "../sass/mixin.scss";

#banner {
  position: relative;
  background: linear-gradient(45deg, $backgroud, transparent);
  main {
    img {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0.5;
      z-index: -10;
      object-fit: cover;
    }
    @include banner();
    h1 {
      color: $primary;
      letter-spacing: 4.8px;
      font-size: 10em;
      line-height: 89.5%;
    }
    p {
      color: $secondary;
      display: inline-block;
      text-align: left;
      font-size: 1.5em;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

@media screen and (max-width: 1155px) {
  #banner {
    main {
      h1 {
        font-size: 6em;
      }
      p {
        font-size: 1em;
      }
    }
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 420px) {
  #banner {
    main {
      h1 {
        font-size: 4em;
      }
      p {
        font-size: 0.9em;
      }
    }
  }
}
