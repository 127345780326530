@import "../sass/colors.scss", "../sass/mixin.scss";

#socials {
  background-color: rgb(19, 19, 19);
  section {
    @include page();
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;

    i {
      color: $primary;
      transition: color 0.3s ease, transform 0.3s ease;

      &:hover {
        color: $primary-emphasis;
        transform: scale(1.2);
      }
    }
  }
}

@media screen and (max-width: 1155px) {
  #socials {
    section {
      padding: 30px 10%;
      i {
        transform: scale(0.8);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #socials {
    section {
      padding: 15px 10%;

      i {
        transform: scale(0.6);
      }
    }
  }
}

@media screen and (max-width: 420px) {
  #socials {
    section {
      padding: 15px 10%;

      i {
        transform: scale(0.5);
      }
    }
  }
}
