@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import "./colors.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  font-family: "IBM Plex Mono", monospace;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  background: linear-gradient(45deg, $backgroud, $backgroud, $backgroud);
  overflow-x: hidden;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
button {
  overflow-wrap: break-word;

  &::selection {
    background-color: $hover;
    color: black;
  }
}

#root,
#__next {
  isolation: isolate;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #1b1b1b;
}

::-webkit-scrollbar-thumb {
  background: #313131;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #313131;
}
