@import "../sass/colors.scss", "../sass/mixin.scss";

#footer {
  background-color: rgb(19, 19, 19);
  footer {
    @include page();
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;

    p {
      color: $secondary;
    }
  }
}
